import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import CreateArtical from "../components/CreateArtical";

const CreateArticalPage = () => {
    return (
        <Layout pageTitle="Kipso | Artical">
            <NavOne />
            <PageHeader title="Artical" />
            <CreateArtical />
            <Footer />
        </Layout>
    );
};

export default CreateArticalPage;
